import React from "react";

import SecondPaper from "./SecondPaper/SecondPaper";

const Secondmain = () => {
  return (
    <div>
      <SecondPaper />
    </div>
  );
};

export default Secondmain;
