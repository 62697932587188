import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Pdffile from "../Firstmain/pdf-files/sample.pdf";
import paper1 from "./images/paper1.png";
import first from "./images/first.png";
import second from "./images/second.png";
import third from "./images/third.png";
import location from "./images/location.png";
import "../NewsExapndSection/NewsExapndSection.css";
import { useContext } from "react";
import { Context } from "../../utils/context";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import pageicon from "../header/Image/page-icon.png";

const NewsExpandSection = () => {
  






  const {
    getData,
    mainPage,
    setMainPage,
    zoneid,
    setZoneid,
    date,
    setDate,
    myData,
    setmyData,
    IMG_URL,
    papercurrentIndex,
    setPaperCurrentIndex,
  } = useContext(Context);
  const [particulerNews, setParticulerNews] = useState();
  const { id } = useParams();
  const getperticularNewsGet = async () => {
    const resperticularNewsGet = await getData(`/perticularNews?id=${id}`);
    if (resperticularNewsGet?.success) {
      setParticulerNews(resperticularNewsGet?.data);
    }
  };
  function sideNewshandleClick(e) {
    const selectedOptionValue = e;
    setPaperCurrentIndex(selectedOptionValue);
  }

  const [numPages, setNumPages] = useState(null);
  function onDocumentSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    getperticularNewsGet();
  }, []);

  return (
    <>
      <section className="paper-scroll">
        <div className="mumbai">
          <div className="d-flex">
            <div className="mumbai-location me-2">
              <img className="location" src={location} />
            </div>
            <div className="text1">
              {particulerNews?.innerPages?.map((val, index) =>
                index === 0 ? (
                  <p className="text">{val?.paper?.city?.name}</p>
                ) : null
              )}
            </div>
          </div>
        </div>

      
        
        {/* <div className="row">
          <div className="col-md-12">
            <div className="tabs-sec-div">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-hellos-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-hellos"
                    type="button"
                    role="tab"
                    aria-controls="pills-hellos"
                    aria-selected="true"
                  >
                    Hellos
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-supplements-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-supplements"
                    type="button"
                    role="tab"
                    aria-controls="pills-supplements"
                    aria-selected="false"
                  >
                    Supplements
                  </button>
                </li>
              </ul>

              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-hellos"
                  role="tabpanel"
                  aria-labelledby="pills-hellos-tab"
                  tabindex="0"
                >
                  <div className="small-paper-view">
                    {particulerNews?.innerPages?.map((val) => (
                      <Link
                        onClick={() => sideNewshandleClick(val?.page_no_id)}
                      >
                        <img
                          className="paper-img mt-4"
                          src={IMG_URL + val?.news}
                          alt="..."
                        />
                      </Link>
                    ))}
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-supplements"
                  role="tabpanel"
                  aria-labelledby="pills-supplements-tab"
                  tabindex="0"
                >
                  <div className="small-paper-view">
                    {particulerNews?.innerPages?.map((val) => (
                      <Link onClick={() => sideNewshandleClick(val?.id)}>
                        <img
                          className="paper-img mt-4"
                          src={IMG_URL + val?.news}
                          alt="..."
                        />
                      </Link>
                    ))}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};
export default NewsExpandSection;
