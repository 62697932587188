import React, { useRef } from "react";
import Header from "../header/Header";
import "../MainHomePage/MainHomePage.css";
import cycle from "../MainHomePage/images/cycle.png";
import paper1 from "../MainHomePage/images/paper1.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import dhillon from "../MainHomePage/images/dhillon.png";
import RASHTRA from "../MainHomePage/images/RASHTRA.png";
import youtube from "../MainHomePage/images/youtube.png";
import instagram from "../MainHomePage/images/instagram.png";
import facebook from "../MainHomePage/images/facebook.png";
import twiter from "../MainHomePage/images/twiter.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getCurrentDate, zoneGet, advertisement } from "../../api/Apis";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useContext } from "react";
import { Context } from "../../utils/context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Hourglass, ColorRing } from "react-loader-spinner";

const MainHomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [response, setZoneGet] = useState();
  const handleDateChange = async (date) => {
    setLoading(true);
    if (date) {
      const day = date.getDate(); // Get day (two digits)
      const month = date.getMonth() + 1; // Get month (two digits), adding 1 because months are zero-based
      const year = date.getFullYear(); // Get full year
      const formattedDate = `${year}-0${month}-${day} `;

      await setDate(formattedDate);
      const response = await getData(`/getzone?date=${formattedDate}`);
      await setMainPage(response?.data);
      setLoading(false);
    }
  };

  const [isloading, setLoading] = useState(true);

  const filterFutureDates = (date) => {
    return date <= new Date(); // Disable dates that are greater than the current date
  };

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value}
      <FontAwesomeIcon
        icon={["fas", "calendar-days"]}
        style={{ color: "#000" }}
      />
    </button>
  );

  const {
    getData,
    mainPage,
    setMainPage,
    zoneid,
    setZoneid,
    date,
    setDate,
    paper_type_id,
    setPaper_type_id,
    setIndex,
  } = useContext(Context);

  const [floatings, setFloatNews] = useState();
  const [advertisements, setadvertisement] = useState();
  let data = ["", "", ""];

  const imageUrl = "https://admin.rashtrasanchar.profcymabackend.com/";

  const getnewsGet = async () => {
    const res = await getData("/getzone");
    if (res?.success) {
      // console.log(res.data);
      await setMainPage(res.data);
      setPaper_type_id(1);
      setLoading(false);
    }
  };

  const getFloatNews = async () => {
    const floats = await getData("/floatNews");
    if (floats?.success) {
      setFloatNews(floats?.data?.floatingNews);
    }
  };

  const getadvertisement = async () => {
    setLoading(true);
    const adverties = await getData("/advertisement");
    if (adverties?.success) {
      setadvertisement(adverties.data);
      setLoading(false);
    }
  };

  const handleClick = async (value) => {
    setPaper_type_id(value);
    setLoading(true);
    if (date) {
      const res = await getData(
        `/getzone?zone_id=${zoneid}&paper_type_id=${value}&date=${date}`
      );
      // console.log(res);
      await setMainPage(res?.data);
      setLoading(false);
    } else {
      const res = await getData(
        `/getzone?zone_id=${zoneid}&paper_type_id=${value}&date=${date}`
      );
      // console.log(res);
      await setMainPage(res?.data);
      setLoading(false);
    }
  };
  // console.log(mainPage);

  useEffect(() => {
    getnewsGet();
    getadvertisement();
    getFloatNews();
  }, []);

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  // console.log(mainPage);

  const NavigateToArtical = async (page_no_id, paper_id) => {
    await setIndex(page_no_id - 1);
    await navigate(`/article-view/${paper_id}`);
  };

  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    // console.log("handleMouseEnter");
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  return isloading ? (
    <>
      <ColorRing
        className="blocks-wrapper"
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
      />
    </>
  ) : (
    <>
      {/* <Homepage /> */}
      <section className="MainHomaPage">
        <div className="container-fluid p-0">
          <div className="mainhome-header">
            <div className="related-new-slider-div">
              <Swiper
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                slidesPerView={4}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  575: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                  1500: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                className="mySwiper"
              >
                {floatings?.map((val) => (
                  <SwiperSlide>
                    <div className="slide-news-div">
                      <div className="d-flex">
                        <div className="img-div">
                          <img
                            className="news-imggg"
                            src={imageUrl + val?.image}
                          />
                        </div>
                        <Link
                          to={val?.url}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className="news-div my-auto">
                            <p>
                              {val?.heading.length > 60
                                ? val.heading.substring(0, 60) + "..."
                                : val.heading}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="row ms-0 me-0 mt-3 justify-content-center">
            <div className="col-md-10">
              <div className="homepage-background">
                <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-8">
                    <ul
                      class="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      {mainPage?.paperType?.map((val, index) => (
                        <li class="nav-item" role="presentation" key={index}>
                          <button
                            className={
                              val.id === paper_type_id
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                            onClick={() => handleClick(val?.id)}
                          >
                            <p className="mukhya">{val?.name}</p>
                          </button>
                        </li>
                      ))}
                    </ul>

                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        {/* start news loop  */}
                        <div className="row">
                          {mainPage?.paper?.data.map((val, index) => (
                            <div className="col-md-4" key={index}>
                              <p className="mumbai text-center">
                                . {val?.city?.name} .
                              </p>
                              <div className="newspaper-one">
                                <Link
                                  onClick={() =>
                                    NavigateToArtical(val?.page_no_id, val?.id)
                                  }
                                  // to={`/article-view/${val?.paper_id}`}
                                >
                                  <img
                                    className="rashtra me-2"
                                    src={imageUrl + val?.news}
                                  />
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* end news loop  */}

                        {/* start video add section  */}
                        {advertisements?.advertisement?.map((word, index) =>
                          word.type === "midad" ? (
                            <div className="add-video-sec">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="video-div">
                                    <video class="add-video" controls>
                                      <source
                                        src={imageUrl + word.file}
                                        type="video/webm"
                                      />
                                    </video>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                        {/* end video add section  */}
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabindex="0"
                      ></div>

                      <div
                        class="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabindex="0"
                      >
                        {/* start video add section  */}
                        {advertisements?.advertisement?.map((word, index) =>
                          word.type === "midad" ? (
                            <div className="add-video-sec">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="video-div">
                                    <video
                                      className="add-video"
                                      playsInline
                                      loop
                                      muted
                                      controls
                                      alt="All the devices"
                                      src={imageUrl + word.file}
                                      ref={videoEl}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                        {/* end video add section  */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3">
                    <div className="jahirat">
                      <div className="row">
                        <div
                          className=""
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <p className="name text-center mt-2">जाहिरात</p>

                          <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                              delay: 2500,
                            }}
                            pagination={{
                              clickable: true,
                            }}
                            loop={true}
                            ref={swiperRefLocal}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                          >
                            {advertisements?.advertisement?.map((word, index) =>
                              word.type === "sidead" ? (
                                <SwiperSlide key={index}>
                                  <span>
                                    <img
                                      className="rashtra me-2"
                                      src={imageUrl + word?.file}
                                    />
                                  </span>
                                </SwiperSlide>
                              ) : null
                            )}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MainHomePage;
