import React, {useEffect, useRef, useState ,Component } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import Pdffile from "./sample.pdf";

// import { PDFViewer, PDFLinkService, PDFDocument } from 'react-pdf';
// import { AnnotationLayer } from 'react-pdf-annotation-tool';

// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';

// import ReactDOM from 'react-dom';
// import { PDFViewer } from '@react-pdf/renderer';

// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';



import "../OnePageNo/OnePageNo.css";
import Header from "../../header/Header";
import NewsExpandSection from "../../NewsExapndSection/NewsExapndsection";
import galery from "../images/galery.png";
import cycle from "../images/cycle.png";
import text from "../images/text.png";
import arrow from "../images/arrow.png";
import download from "../images/download.png";
import paper1 from "../images/paper1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import twiter from "../images/twiter.png";
import youtube from "../images/youtube.png";
import zoompaper1 from "../images/zoompaper1.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import dhillon from "../images/dhillon.png";
import RASHTRA from "../images/RASHTRA.png";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import { PDFDocument, rgb } from 'pdf-lib';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";


// import WebViewer from '@pdftron/webviewer';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import samplePDF from './sample.pdf';


const  OnePageNo = () => {



  // const [numPages, setNumPages] = useState(null);

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  //   console.log(numPages);
  // };


//   ReactPDF.renderToStream(<MyDocument />);
// ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);

// const [modifiedPdfBytes, setModifiedPdfBytes] = useState();

//   const loadPDF = async () => {
//     try {
//       const pdfDoc = await PDFDocument.load(samplePDF);
//       const page = pdfDoc.getPage(0);
    
//       const { width, height } = page.getSize();
//       const text = 'Hello, PDF Editor!';
//       const fontSize = 24;
    
//       page.drawText(text, {
//         x: width / 2 - text.length * fontSize / 4,
//         y: height / 2,
//         size: fontSize,
//         color: rgb(0, 0, 0),
//       });
    
//       const modifiedBytes = await pdfDoc.save();
//       setModifiedPdfBytes(modifiedBytes);
//     } catch (error) {
//       console.error("Error loading or editing PDF:", error);
//     }
//   };
  
//   useEffect(() => {
//     loadPDF();
//   }, []);

//   console.log("Modified PDF Bytes Length:", modifiedPdfBytes?.length , " " + samplePDF);


const [numPages , setNumPages] = useState(null)
function onDocumentSuccess({numPages}){
  setNumPages(numPages)
}

  return (
    <>
      {/* <Homepage /> */}
      <div className="row me-0">
        <div className="col-md-2">
          <NewsExpandSection />
        </div>

        <div className="col-md-10">
          <div className="main-content-section">
            <div className="white header">
              <div className="row">
                <div className="col-md-3">
                  <div className="d-flex">
                    <div className="img1 me-2">
                      <img className="cycle mt-2" src={cycle} />
                    </div>
                    <div className="text1 mt-2">
                      <p className="text1">
                       
                        मैत्रीदिन व रक्षाबंधन निमित्त सायकल भेट
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex">
                    <div className="img1 me-2">
                      <img className="cycle mt-2" src={cycle} />
                    </div>
                    <div className="text1 mt-2">
                      <p className="text1">
                        मैत्रीदिन व रक्षाबंधन निमित्त सायकल भेट
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex">
                    <div className="img1 me-2">
                      <img className="cycle mt-2" src={cycle} />
                    </div>
                    <div className="text1 mt-2">
                      <p className="text1">
                        मैत्रीदिन व रक्षाबंधन निमित्त सायकल भेट
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex">
                    <div className="img1 me-2">
                      <img className="cycle mt-2" src={cycle} />
                    </div>
                    <div className="text1 mt-2">
                      <p className="text1">
                        मैत्रीदिन व रक्षाबंधन निमित्त सायकल भेट
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="row  justify-content-center">
                  <div className="main-swiper">
                    <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <img className="paper mt-5" src={paper1} />
                      </SwiperSlide>
                      <SwiperSlide>
                       
                        <img className="paper mt-5" src={paper1} />
                      </SwiperSlide>
                      <SwiperSlide>
                       
                        <img className="paper mt-5" src={paper1} />
                      </SwiperSlide>
                      <SwiperSlide>
                      
                        <img className="paper mt-5" src={paper1} />
                      </SwiperSlide>
                      <SwiperSlide>
                       
                        <img className="paper mt-5" src={paper1} />
                      </SwiperSlide>
                      <SwiperSlide>Slide 6</SwiperSlide>
                      <SwiperSlide>Slide 7</SwiperSlide>
                      <SwiperSlide>Slide 8</SwiperSlide>
                      <SwiperSlide>Slide 9</SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
              <div className="col-md-9">


                <div className="tab-background">


                          <div className="" style={{ justifyContent: "center"}} >
                            <div className="" style={{width:"80%" , border: "3px solid gray"}}>
                                <Document file={Pdffile} onloadSuccess={onDocumentSuccess} >
                                    {
                                      Array(numPages).fill().map((_, i) =>(
                                        <Page pageNumber={i+1}></Page>
                                      ))
                                    }
                                    
                                </Document>
                            </div>
                          </div>

                  {/* <div className="container">
                    <div className="">
                      <ul
                        class="nav nav-pills justify-content-start "
                        id="pills-tab"
                        role="tablist"
                      >
                        
                          <li class="nav-item mt-3" role="presentation">
                            <button
                              class="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <div className="d-flex">
                                <div className="orange-img">
                                  <img className="galery me-2" src={galery} />
                                </div>
                                <div className="orange-text">
                                  <p className="pratima">प्रतिमा</p>
                                </div>
                              </div>
                            </button>
                          </li>
                       
                        
                          <li class="nav-item mt-3" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <div className="d-flex">
                                <div className="orange-img">
                                  <img className="galery me-2" src={text} />
                                </div>
                                <div className="orange-text">
                                  <p className="pratima">मजकूर</p>
                                </div>
                              </div>
                            </button>
                          </li>
                        

                        
                          <Link to="/typeset">
                            <li class="nav-item mt-3" role="presentation">
                              <button
                                class="nav-link"
                                id="pills-contact-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-contact"
                                type="button"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                              >
                                <div className="d-flex">
                                  <div className="orange-img">
                                    <img className="galery me-2" src={arrow} />
                                  </div>
                                  <div className="orange-text">
                                    <p className="pratima">पूर्ण दृश्य</p>
                                  </div>
                                </div>
                              </button>
                            </li>
                          </Link>
                        

                        
                          <li class="nav-item mt-3" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-display-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-display"
                              type="button"
                              role="tab"
                              aria-controls="pills-display"
                              aria-selected="false"
                            >
                              <div className="d-flex">
                                <div className="orange-img">
                                  <img className="galery me-2" src={download} />
                                </div>
                                <div className="orange-text">
                                  <p className="pratima">प्रतिमा डाउनलोड करा</p>
                                </div>
                              </div>
                            </button>
                          </li>
                        
                        
                          <li class="nav-item mt-3" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-last-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-last"
                              type="button"
                              role="tab"
                              aria-controls="pills-last"
                              aria-selected="false"
                            >
                              <div className="d-flex">
                                <div className="orange-img">
                                 
                                </div>
                                <div className="orange-text">
                                  <p className="pratima">अ अक्षराचा आकार</p>
                                </div>
                              </div>
                            </button>
                          </li>
                        
                      </ul>
                    </div>

                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        <div className="row mt-3">
                          <div className="col-md-9">
                            <div className="paper1">
                              <img className="news1 mt-2" src={paper1} />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <div className="jahirat ">
                                <p className="name text-center mt-2">जाहिरात</p>

                                <Carousel
                                  autoPlay={true}
                                  showArrows={false}
                                  showIndicators={false}
                                  showStatus={false}
                                  showThumbs={false}
                                  infiniteLoop={true}
                                  stopOnHover={false}
                                  axis="vertical"
                                >
                                  {data.map((word, index) => (
                                    <span key={index}>
                                      <b>{word}</b>
                                      <img
                                        className="rashtra me-2 mt-1"
                                        src={RASHTRA}
                                      />
                                     
                                    </span>
                                  ))}
                                </Carousel>
                                <div className="row justify-content-center">
                                <img className="dhillon tecxt-center mt-1" src={dhillon} />
                                </div>
                              
                              </div>
                            
                            </div>

                            
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabindex="0"
                      >
                        <div className="row mt-3">
                          <div className="col-md-9">
                            <div className="paper1">
                              <img
                                className="zoompaper1 mt-2"
                                src={zoompaper1}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <div className="jahirat ">
                                <p className="name text-center mt-2">जाहिरात</p>

                                <Carousel
                                  autoPlay={true}
                                  showArrows={false}
                                  showIndicators={false}
                                  showStatus={false}
                                  showThumbs={false}
                                  infiniteLoop={true}
                                  stopOnHover={false}
                                  axis="vertical"
                                >
                                  {data.map((word, index) => (
                                    <span key={index}>
                                      <b>{word}</b>
                                      <img
                                        className="rashtra me-2 mt-1"
                                        src={RASHTRA}
                                      />
                                    
                                    </span>
                                  ))}
                                </Carousel>
                                <div className="row justify-content-center">
                                <img className="dhillon tecxt-center mt-1" src={dhillon} />
                                </div>
                              
                              </div>
                            
                            </div>
                            
                          </div>
                         
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabindex="0"
                      >
                         <div className="col-md-3">
                            <div className="row">
                              <div className="jahirat ">
                                <p className="name text-center mt-2">जाहिरात</p>

                                <Carousel
                                  autoPlay={true}
                                  showArrows={false}
                                  showIndicators={false}
                                  showStatus={false}
                                  showThumbs={false}
                                  infiniteLoop={true}
                                  stopOnHover={false}
                                  axis="vertical"
                                >
                                  {data.map((word, index) => (
                                    <span key={index}>
                                      <b>{word}</b>
                                      <img
                                        className="rashtra me-2 mt-1"
                                        src={RASHTRA}
                                      />
                                     
                                    </span>
                                  ))}
                                </Carousel>
                                <div className="row justify-content-center">
                                <img className="dhillon tecxt-center mt-1" src={dhillon} />
                                </div>
                              
                              </div>
                            
                            </div>
                            <div className="row justify-content-end">
                              <div className="icons">
                                <img
                                  className="icon me-2 mt-1"
                                  src={facebook}
                                />
                                <img
                                  className="icon me-2 mt-2"
                                  src={instagram}
                                />
                                <img className="icon me-2 mt-2" src={twiter} />
                                <img className="icon me-2 mt-1" src={youtube} />
                              </div>
                            </div>
                          </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-display"
                        role="tabpanel"
                        aria-labelledby="pills-display-tab"
                        tabindex="0"
                      >
                        <div className="row">
                          <div className="col-md-9">
                            <div className="background"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                    

                        
                      

                            


                          
                        
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
}
export default OnePageNo;
