import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import {
  getData,
  postData,
  getEditData,
  editData,
  editStatusData,
  deleteData,
} from "./api";

export const Context = createContext();

const AppContext = ({ children }) => {
  const IMG_URL = "https://admin.rashtrasanchar.profcymabackend.com/";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [mainPage, setMainPage] = useState({});
  const [zoneid, setZoneid] = useState(1);
  const [index1, setIndex] = useState(null);
  const [date, setDate] = useState("");
  const [myData, setmyData] = useState({});
  const [shortPaper, setShortPaper] = useState({});
  const [particulerNews, setParticulerNews] = useState();
  const [papercurrentIndex, setPaperCurrentIndex] = useState(0);
  const [paper_type_id, setPaper_type_id] = useState(1);
  const [advertisements, setadvertisement] = useState();
  return (
    <Context.Provider
      value={{
        // minLength,
        // maxLength,
        getData,
        postData,
        getEditData,
        editData,
        editStatusData,
        deleteData,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        mainPage,
        setMainPage,
        zoneid,
        setZoneid,
        date,
        setDate,
        myData,
        setmyData,
        shortPaper,
        setShortPaper,
        IMG_URL,
        particulerNews,
        setParticulerNews,
        papercurrentIndex,
        setPaperCurrentIndex,
        paper_type_id,
        setPaper_type_id,
        index1,
        setIndex,
        advertisements,
        setadvertisement,
        // ErrorNotify,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
