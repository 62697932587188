import axiosClient from "./ApiInstance.js";
import { resolve } from "./Resolve.js";

export async function newsGet() {
  return await resolve(
    axiosClient.get("/newsGet", {}).then((res) => res.data)
    //   console.log()
  );
}

export async function zoneGet() {
  return await resolve(axiosClient.get("/getzone", {}).then((res) => res.data));
}

export async function advertisement() {
  return await resolve(
    axiosClient.get("/advertisement", {}).then((res) => res.data)
  );
}
export function getCurrentDate(newDate = new Date(), separator = " / ") {
  let date = newDate.getDate();
  let monthIndex = newDate.getMonth();
  let year = newDate.getFullYear();

  // Array of month names
  // const monthNames = [
  //   "जानेवारी",
  //   "फेब्रुवारी",
  //   "मार्च",
  //   "एप्रिल",
  //   "मे",
  //   "जून",
  //   "जुलै",
  //   "ऑगस्ट",
  //   "सप्टेंबर",
  //   "ऑक्टोबर",
  //   "नोव्हेंबर",
  //   "डिसेंबर",
  // ];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let monthName = monthNames[monthIndex];

  return `${monthName} ${date}${separator}${year}`;
}
