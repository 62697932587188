import axios from "axios";

export const getData = async (url, data) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getEditData = async (url) => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + url);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const editData = async (url, data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const editStatusData = async (url) => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + url);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + url);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
