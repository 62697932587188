import React from 'react'
import OnePageNo from './OnePageNo/OnePageNo';




const Firstmain = () => {
    return (
        <div>
      
         <OnePageNo/>
        
        </div>
    )
}

export default Firstmain;