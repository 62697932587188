import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./Components/header/Header";
import Firstmain from "../src/Components/Firstmain/Firstmain";
// import TypeSetPage from './Components/TypeSetPage/TypeSetPage';
import Secondmain from "./Components/Secondmain/Secondmain";
import MainHomePage from "./Components/MainHomePage/MainHomePage";
import Footer from "../src/Components/Footer/Footer";
import NewsDetails from "./Components/NewsDetails/NewsDetails";
import AppContext from "./utils/context";

function App() {
  return (
    <>
      <AppContext>
        <Header />

        <Routes>
          <Route path="/" element={<MainHomePage />} />
          <Route path="/paper1" element={<Firstmain />} />
          {/* <Route path="/typeset" element={<TypeSetPage/>} /> */}
          <Route path="/article-view/:id" element={<Secondmain />} />
          {/* <Route path="/news-details/:id" element={<NewsDetails />} /> */}
        </Routes>
        <Footer />
      </AppContext>
    </>
  );
}

export default App;
