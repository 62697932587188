import React from "react";
import "../header/Header.css";
import logo from "../header/Image/rashtrasanchar_logo.png";
import rashtra_logo from "../header/Image/pandhari.png";
import pandhari_logo from "../header/Image/pandhari2.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Firstmain from "../Firstmain/Firstmain";
import NewsExpandSection from "../NewsExapndSection/NewsExapndsection";
import { useState, useEffect } from "react";
import pageicon from "../header/Image/page-icon.png";
import { getCurrentDate, zoneGet } from "../../api/Apis";
import { useLocation, useParams } from "react-router-dom";
import youtube from "../MainHomePage/images/youtube.png";
import instagram from "../MainHomePage/images/instagram.png";
import facebook from "../MainHomePage/images/facebook.png";
import twiter from "../MainHomePage/images/twiter.png";
import whatsapp from "../MainHomePage/images/whatsapp.png";
import { useContext } from "react";
import { Context } from "../../utils/context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";

library.add(fas);
const Header = () => {
  const location = useLocation();
  // console.log("cuurent page", location.pathname);

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value}
      <FontAwesomeIcon
        icon={["fas", "calendar-days"]}
        style={{ color: "#000" }}
      />
    </button>
  );
  const {
    getData,
    setMainPage,
    zoneid,
    setZoneid,
    date,
    setDate,
    particulerNews,
    setParticulerNews,
    IMG_URL,
    papercurrentIndex,
    setPaperCurrentIndex,
    paper_type_id,
    setPaper_type_id,
    index1,
    setIndex,
  } = useContext(Context);
  const [setzoneId, setzoneid] = useState();
  const [response1, setZoneGet] = useState();
  const getzoneGet = async () => {
    const res = await getData("/getzone");
    if (res?.success) {
      await setZoneGet(res.data.zones);
    }
  };
  const [socialmedia, setSocialmedia] = useState();
  const getSocialMedia = async () => {
    const res = await getData("/getSocial");
    // console.log(res.data.socialMedia);
    if (res?.success) {
      await setSocialmedia(res.data.socialMedia);
    }
  };
  // console.log(index1);
  // Function to block future dates
  const filterFutureDates = (date) => {
    return date <= new Date(); // Disable dates that are greater than the current date
  };
  useEffect(() => {
    getzoneGet();
    getSocialMedia();
  }, []);
  // const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = async (date) => {
    if (date) {
      const day = date.getDate(); // Get day (two digits)
      const month = date.getMonth() + 1; // Get month (two digits), adding 1 because months are zero-based
      const year = date.getFullYear(); // Get full year
      const formattedDate = `${year}-0${month}-${day} `;

      await setDate(formattedDate);
      const response = await getData(`/getzone?date=${formattedDate}`);
      await setMainPage(response?.data);
    }
  };

  const handleClick = async (e) => {
    try {
      await setZoneid(e.target.value);
      const response = await getData(
        `/getzone?zone_id=${e.target.value}&paper_type_id=${paper_type_id}&date=${date}`
      );
      await setMainPage(response?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSelectChange = async (e) => {
    // console.log(e);

    const id = e?.target?.value == undefined ? e : e?.target?.value;

    const response = await getData(`/perticularNews?page_id=${id}`);

    await setIndex(response?.data?.index);
    await setParticulerNews(response?.data);
  };

  const downloadImage = async (id) => {
    // console.log("page", id);
    const xhr = new XMLHttpRequest();
    const imageUrl =
      process.env.REACT_APP_BASE_URL + `/download-image?id=${id}`;
    // console.log(imageUrl);
    xhr.open("GET", imageUrl);
    // Fetch the image as a Blob
    fetch(imageUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        // console.log(response);
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Rashtra_Sanchar_single-page_image.jpg"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  function paperDownload(id) {
    const xhr = new XMLHttpRequest();
    const pdfUrl =
      process.env.REACT_APP_BASE_URL + `/download-image?paper_id=${id}`; // Replace with your PDF download URL
    xhr.open("GET", pdfUrl);

    // Fetch the PDF as a Blob
    fetch(pdfUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const blob = new Blob([buffer], { type: "application/pdf" }); // Set the content type to PDF
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const date = new Date();
          link.setAttribute("download", `Rashtra_Sanchar_${date}.pdf`); // Specify the PDF file name
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.error("Error downloading PDF file:", error);
      });
    // const xhr = new XMLHttpRequest();
    // const imageUrl =
    //   process.env.REACT_APP_BASE_URL + `/download-image?paper_id=${id}`;
    // // console.log(imageUrl);
    // xhr.open("GET", imageUrl);
    // // Fetch the image as a Blob
    // fetch(imageUrl, {
    //   method: "GET",
    //   headers: {},
    // })
    //   .then((response) => {
    //     // console.log(response);
    //     response.arrayBuffer().then(function (buffer) {
    //       const blob = new Blob([buffer], { type: "application/zip" }); // Set the content type to ZIP
    //       const url = window.URL.createObjectURL(blob);
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "downloaded-file.zip"); // Specify the ZIP file name
    //       document.body.appendChild(link);
    //       link.click();
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading ZIP file:", error);
    //   });
  }

  // const downloadImage = async (imageUrl) => {
  //   await fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       // Create a temporary anchor element
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;

  //       // Extract the filename from the URL
  //       const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

  //       // Set the download attribute and filename
  //       link.setAttribute("download", filename);
  //       document.body.appendChild(link);

  //       // Simulate a click on the anchor element to start the download
  //       link.click();

  //       // Clean up the temporary anchor element
  //       link.parentNode.removeChild(link);

  //       // Set the downloaded image URL to display on the page
  //       console.log(url);
  //       // setImageUrl(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading image:", error);
  //     });
  // };

  return (
    <>
      <div className="main sticky-top-header">
        <div className="container-fluid">
          {/* start desktop header */}
          <section className="header">
            <div className="row ms-0 me-0">
              <div className="col-md-12 col-12 p-0">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="row">
                      {/* main logo start */}
                      <div className="col-lg-2 col-12 text-center">
                        <div className="logo-img-holder1">
                          <Link to="/">
                            <img className="img" src={logo} />
                          </Link>
                        </div>
                      </div>
                      {/* main logo start */}

                      <div className="col-lg-10 col-12">
                        <div className="row">
                          <div className="col-md-12 col-12 p-0">
                            {/* header-1 start */}
                            <div className="header-1">
                              <nav className="navbar navbar-expand-lg ">
                                <div className="container-fluid">
                                  <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarscroll"
                                    aria-controls="navbarscroll"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                  >
                                    <span className="navbar-toggler-icon"></span>
                                  </button>

                                  <div
                                    className="collapse navbar-collapse"
                                    id="navbarscroll"
                                  >
                                    <div className="navbar-nav me-auto mb-2 mb-lg-0 ">
                                      <li className="nav-item">
                                        <Link
                                          className="nav-link active"
                                          aria-current="page"
                                          to=""
                                        >
                                          English
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link" to="">
                                          Marathi
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link" to="">
                                          Hindi
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link" to="">
                                          E-Paper
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link " to="">
                                          Manoranjan
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link " to="">
                                          Astro
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link " to="">
                                          Book Classified Ad
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link className="nav-link " to="">
                                          Maha Marathon
                                        </Link>
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </nav>
                            </div>

                            {/* header-1 end */}
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-12 col-12 p-0">
                            {/* header-2 start */}
                            <div className="header-2">
                              <nav className="navbar navbar-expand-lg p-0">
                                <div className="container-fluid">
                                  <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarTogglerDemo01"
                                    aria-controls="navbarTogglerDemo01"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                  >
                                    <span className="navbar-toggler-icon"></span>
                                  </button>
                                  <div
                                    className="collapse navbar-collapse"
                                    id="navbarTogglerDemo01"
                                  >
                                    <div className="navbar-nav me-auto mb-2 mb-lg-0 ">
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link active"
                                          id="home-link"
                                          aria-current="page"
                                          to="/"
                                        >
                                          होम
                                        </Link>
                                      </li>
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%A4%E0%A4%BE%E0%A4%9C%E0%A5%8D%E0%A4%AF%E0%A4%BE-%E0%A4%AC%E0%A4%BE%E0%A4%A4%E0%A4%AE%E0%A5%8D%E0%A4%AF%E0%A4%BE/"
                                        >
                                          ताज्या बातम्या
                                        </Link>
                                      </li>

                                      <li className="nav-item">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%A6%E0%A5%87%E0%A4%B6-%E0%A4%B5%E0%A4%BF%E0%A4%A6%E0%A5%87%E0%A4%B6/"
                                        >
                                          देश-विदेश
                                        </Link>
                                      </li>

                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/maharashtra/"
                                        >
                                          महाराष्ट्र
                                        </Link>
                                      </li>

                                      <li className="nav-item">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%B8%E0%A4%BF%E0%A4%9F%E0%A5%80-%E0%A4%85%E0%A4%AA%E0%A4%A1%E0%A5%87%E0%A4%9F%E0%A5%8D%E0%A4%B8/"
                                        >
                                          सिटी अपडेट्स
                                        </Link>
                                      </li>

                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%B0%E0%A4%A3%E0%A4%A7%E0%A5%81%E0%A4%AE%E0%A4%BE%E0%A4%B3%E0%A5%80/"
                                        >
                                          रणधुमाळी
                                        </Link>
                                      </li>

                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%B5%E0%A4%BF%E0%A4%B6%E0%A5%8D%E0%A4%B2%E0%A5%87%E0%A4%B7%E0%A4%A3/"
                                        >
                                          विश्लेषण
                                        </Link>
                                      </li>
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%AB%E0%A4%BF%E0%A4%9A%E0%A4%B0/"
                                        >
                                          फिचर
                                        </Link>
                                      </li>
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%B8%E0%A4%82%E0%A4%AA%E0%A4%BE%E0%A4%A6%E0%A4%95%E0%A5%80%E0%A4%AF/"
                                        >
                                          संपादकीय
                                        </Link>
                                      </li>
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%B8%E0%A4%82%E0%A4%A1%E0%A5%87-%E0%A4%AB%E0%A4%BF%E0%A4%9A%E0%A4%B0-2/"
                                        >
                                          संडे फिचर
                                        </Link>
                                      </li>
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/category/%E0%A4%87%E0%A4%A4%E0%A4%B0/"
                                        >
                                          इतर
                                        </Link>
                                      </li>
                                      <li className="nav-item ">
                                        <Link
                                          target="_blank"
                                          className="nav-link"
                                          to="https://rashtrasanchar.com/smart-chef/"
                                        >
                                          स्मार्ट शेफ
                                        </Link>
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </nav>
                            </div>
                            {/* header-2 end */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* header-3 start */}
                  <div className="header-3">
                    <div className="row me-0 ms-0 p-0">
                      <div className="col-lg-10 my-auto  p-0">
                        <div className="line"></div>
                      </div>

                      <div className="col-lg-2  col-4 text-center border-right my-auto">
                        <div className="logo-img-holder2">
                          <img className="img" src={logo} to="" />
                        </div>
                        <div className="vertical-line right"></div>
                      </div>

                      {/* <div className="col-lg-2 col-4 text-center p-0 my-auto"> */}
                      {/* <div className="pandhari-text">Pandhari Times</div> */}
                      {/* </div> */}
                    </div>
                  </div>

                  {/* header-3 end */}

                  {/* header-4 start */}

                  <div className="header-4">
                    <div className="row ">
                      <div className="col-md-2 col-6 p-0">
                        <div className="d-flex">
                          <div className="home-icon">
                            <Link className="navbar-brand" to="/">
                              <FontAwesomeIcon
                                icon="fa-solid fa-house"
                                style={{
                                  color: "#f1f2f3",
                                  width: "25px",
                                  height: "25px",
                                }}
                              >
                                <span class="tooltiptext">Tooltip text</span>
                              </FontAwesomeIcon>
                            </Link>
                          </div>
                          <div className="calender my-auto ps-2">
                            {location.pathname == "/" ? (
                              <Link className="navbar-brand" to="">
                                <DatePicker
                                  // dateFormat="YYYY-MM-DD"
                                  // selected={new Date(date)}
                                  onChange={handleDateChange}
                                  isClearable
                                  filterDate={filterFutureDates}
                                  customInput={<ExampleCustomInput />}
                                />
                                {/* <FontAwesomeIcon
                                icon="fa-solid fa-calendar-days"
                                style={{ color: "#ffffff" }}
                              /> */}
                                {date
                                  ? getCurrentDate(new Date(date))
                                  : getCurrentDate()}
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 my-auto">
                        {location.pathname != "/" ? (
                          <div className="e-pages-section">
                            <ul className="d-flex">
                              {!location.pathname.includes("/news-details") ? (
                                <>
                                  <li>
                                    {/* <Pagination className="">
                                      {particulerNews?.innerPages?.map(
                                        (val, index) => (
                                          <>
                                            <Pagination.Item
                                              className="m-0"
                                              key={val.id}
                                              value={val.id}
                                              active={
                                                1 + index1 === val.page_no_id
                                              }
                                              onClick={() =>
                                                handleSelectChange(val.id)
                                              }
                                            >
                                              {val.page_no_id}
                                            </Pagination.Item>
                                          </>
                                        )
                                      )}
                                    </Pagination> */}

                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      onChange={handleSelectChange}
                                    >
                                      <option disabled>
                                        <img value="" src={pageicon} /> पान क्र.
                                      </option>
                                      {particulerNews?.innerPages?.map((val) =>
                                        1 + index1 === val.page_no_id ? (
                                          <option value={val.id} selected>
                                            {val.page_no_id}
                                          </option>
                                        ) : (
                                          <option value={val.id}>
                                            {val.page_no_id}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </li>
                                  <li style={{ color: "white" }}>
                                    / {particulerNews?.innerPages?.length}
                                  </li>
                                </>
                              ) : null}

                              {particulerNews?.innerPages?.map((val, index) =>
                                index1 === index ? (
                                  <>
                                    {location.pathname != "/" ? (
                                      <li className="text-li">
                                        <Link
                                          download
                                          className="link"
                                          onClick={() => {
                                            downloadImage(val?.id);
                                          }}
                                          // href={IMG_URL + val?.news}
                                          // onClick={() => {
                                          //   downloadImage(IMG_URL + val?.news);
                                          // }}
                                          // target="_top"
                                          // download="jpg"
                                          // rel="noreferrer"
                                        >
                                          <span className="page-dwn-text">
                                            <FontAwesomeIcon
                                              icon="fa-solid fa-file"
                                              className="me-2"
                                            />
                                            पान डाउनलोड करा
                                          </span>
                                        </Link>
                                      </li>
                                    ) : null}
                                    {/* location.pathname != "/news-details"  */}
                                    {!location.pathname.includes(
                                      "/news-details"
                                    ) ? (
                                      <li className="text-li">
                                        <Link className="link">
                                          <span
                                            className="page-dwn-text"
                                            onClick={() => {
                                              paperDownload(val?.paper_id);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon="fa-solid fa-file-circle-plus"
                                              className="me-2"
                                            />
                                            पूर्ण आवृत्ती डाउनलोड करा
                                          </span>
                                        </Link>
                                      </li>
                                    ) : null}
                                  </>
                                ) : (
                                  <></>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-2 my-auto">
                        <div className="edition-div">
                          {location.pathname != "/" ? null : (
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={handleClick}
                            >
                              {response1?.map((val) =>
                                val.id === 1 ? (
                                  <option value={val?.id} selected>
                                    {val?.name}
                                  </option>
                                ) : (
                                  <option value={val?.id}> {val?.name}</option>
                                )
                              )}
                            </select>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-2 my-auto">
                        <div className="edition-div">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={handleClick}
                          >
                            {response?.map((val) =>
                              val.id === 1 ? (
                                <option value={val?.id} selected>
                                  {" "}
                                  {val?.name}
                                </option>
                              ) : (
                                <option value={val?.id}> {val?.name}</option>
                              )
                            )}
                          </select>
                        </div>
                      </div> */}

                      <div className="col-md-2 ms-auto my-auto">
                        <div className="social-icons-stripped">
                          <div className="row justify-content-end">
                            <div className="icons text-center">
                              {socialmedia?.map((data, index) => {
                                return (
                                  <Link key={index}>
                                    <img
                                      onClick={() => window.open(data?.url)}
                                      className="icon"
                                      src={IMG_URL + data?.icons}
                                    />
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* header-4 end */}
                </div>
              </div>
            </div>
          </section>
          {/* end desktop header */}

          {/* start mobile-header */}
          <section className="mobile-header">
            <div className="head-baar-div d-flex">
              <div className="my-auto">
                <FontAwesomeIcon
                  icon="fa-solid fa-bars"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  aria-controls="offcanvasExample"
                />
              </div>

              <div>
                <div class="edition-div mob-edddd ms-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="LOK_MULK"> Mumbai Main</option>
                    <option value="LOK_MULK">Mumbai Main Edition</option>
                    <option value="LOK_ANLK">Ahmednagar Main Edition</option>
                    <option value="LOK_AKLK">Akola Main Edition</option>
                    <option value="LOK_AULK">Aurangabad Main Edition</option>
                    <option value="LOK_GALK">Goa Main Edition</option>
                    <option value="LOK_JLLK">Jalgaon Main Edition</option>
                    <option value="LOK_KOLK">Kolhapur Main Edition</option>
                    <option value="LOK_NPLK">Nagpur Main Edition</option>
                    <option value="LOK_NSLK">Nashik Main Edition</option>
                    <option value="LOK_PULK">Pune Main Edition</option>
                    <option value="LOK_SOLK">Solapur Main Edition</option>
                  </select>
                </div>
              </div>

              <Link to="/">
                <img className="top-logg ms-2" src={logo} />
              </Link>
            </div>

            <div
              className="offcanvas offcanvas-start"
              tabindex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <Link to="/">
                  <img className="logo" src={logo} />
                </Link>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>

              <div className="offcanvas-body p-0">
                <div className="date-strip">
                  <div className="row me-0 ms-0">
                    <div className="col-6">
                      <p className="dwn-txt">
                        {/* <FontAwesomeIcon icon="fa-solid fa-mobile-screen" /> */}
                        {/* Download App */}
                      </p>
                    </div>

                    <div className="col-6 text-end">
                      <p className="dwn-txt">{getCurrentDate()}</p>
                    </div>
                  </div>
                </div>

                <div className="follow-icons">
                  <div className="row me-0 ms-0">
                    <div className="col-12 ms-auto">
                      <div className="d-flex justify-content-end">
                        <div className="my-auto">
                          <p className="follow-p">FOLLOW US : </p>
                        </div>
                        <div className="icons-div">
                          {socialmedia?.map((data, index) => {
                            return (
                              <Link key={index}>
                                <img
                                  onClick={() => window.open(data?.url)}
                                  className="icon"
                                  src={IMG_URL + data?.icons}
                                />
                              </Link>
                            );
                          })}
                        </div>
                        {/* <div className="icons-div">
                          <Link to="">
                            <img
                              onClick={() =>
                                window.open(
                                  "https://www.instagram.com/rashtrasanchar_news/?utm_medium=copy_link",
                                  "_blank"
                                )
                              }
                              className="icon"
                              src={instagram}
                            />
                          </Link>
                          <Link to="">
                            <img
                              onClick={() =>
                                window.open(
                                  "https://twitter.com/i/flow/login?redirect_after_login=%2Fsanchar_rashtra",
                                  "_blank"
                                )
                              }
                              className="icon"
                              src={twiter}
                            />
                          </Link>
                          <Link to="">
                            <img
                              onClick={() =>
                                window.open(
                                  "https://www.youtube.com/c/RASHTRASANCHAR",
                                  "_blank"
                                )
                              }
                              className="icon "
                              src={youtube}
                            />
                          </Link>
                          <Link to="">
                            <img
                              onClick={() =>
                                window.open(
                                  "https://wa.me/+9112920137",
                                  "_blank"
                                )
                              }
                              className="icon"
                              src={whatsapp}
                            />
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-div-table mt-2">
                  <div className="row bd-row  ms-0 me-0">
                    <div className="col-6">
                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="/" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-house"
                          />
                          होम
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%E0%A4%A4%E0%A4%BE%E0%A4%9C%E0%A5%8D%E0%A4%AF%E0%A4%BE-%E0%A4%AC%E0%A4%BE%E0%A4%A4%E0%A4%AE%E0%A5%8D%E0%A4%AF%E0%A4%BE/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-newspaper"
                          />
                          ताज्या बातम्या
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%E0%A4%A6%E0%A5%87%E0%A4%B6-%E0%A4%B5%E0%A4%BF%E0%A4%A6%E0%A5%87%E0%A4%B6/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-earth-americas"
                          />
                          देश-विदेश
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/maharashtra/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-bus"
                          />
                          महाराष्ट्र
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%b8%e0%a4%bf%e0%a4%9f%e0%a5%80-%e0%a4%85%e0%a4%aa%e0%a4%a1%e0%a5%87%e0%a4%9f%e0%a5%8d%e0%a4%b8/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-building"
                          />
                          सिटी अपडेट्स
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%b0%e0%a4%a3%e0%a4%a7%e0%a5%81%e0%a4%ae%e0%a4%be%e0%a4%b3%e0%a5%80/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-person-running"
                          />
                          रणधुमाळी
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%b5%e0%a4%bf%e0%a4%b6%e0%a5%8d%e0%a4%b2%e0%a5%87%e0%a4%b7%e0%a4%a3/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-expand"
                          />
                          विश्लेषण
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%ab%e0%a4%bf%e0%a4%9a%e0%a4%b0/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-wrench"
                          />
                          फिचर
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%b8%e0%a4%82%e0%a4%aa%e0%a4%be%e0%a4%a6%e0%a4%95%e0%a5%80%e0%a4%af/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-newspaper"
                          />
                          संपादकीय
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%b8%e0%a4%82%e0%a4%a1%e0%a5%87-%e0%a4%ab%e0%a4%bf%e0%a4%9a%e0%a4%b0-2/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-calendar-days"
                          />
                          संडे फिचर
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/category/%e0%a4%87%e0%a4%a4%e0%a4%b0/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-circle-question"
                          />
                          इतर
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link
                          to="https://rashtrasanchar.com/smart-chef/"
                          className="likk"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-utensils"
                          />
                          स्मार्ट शेफ
                        </Link>
                      </div>
                    </div>

                    <div className="col-6 ps-0">
                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-e"
                          />
                          English
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-m"
                          />
                          Marathi
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-h"
                          />
                          Hindi
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-newspaper"
                          />
                          E-Paper
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-tv"
                          />
                          Manoranjan
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-om"
                          />
                          Astro
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid  fa-images"
                          />
                          Book Classified Ad
                        </Link>
                      </div>

                      <div
                        className="menu-div bd-bt "
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <Link to="" className="likk">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-person-running"
                          />
                          Maha Marathon
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="header-4">
              <div className="row firt-pp-bg">
                <div className="col-md-6 col-6 p-0">
                  <div className="d-flex">
                    {/* <div className="home-icon">
                      <Link className="navbar-brand" to="/">
                        <FontAwesomeIcon
                          icon="fa-solid fa-house"
                          style={{
                            color: "#f1f2f3",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      </Link>
                    </div> */}
                    <div className="calender my-auto ps-2">
                      {location.pathname == "/" ? (
                        <Link className="navbar-brand" to="">
                          <DatePicker
                            // dateFormat="YYYY-MM-DD"
                            // selected={new Date(date)}
                            onChange={handleDateChange}
                            isClearable
                            filterDate={filterFutureDates}
                            customInput={<ExampleCustomInput />}
                          />
                          {/* <FontAwesomeIcon
                                icon="fa-solid fa-calendar-days"
                                style={{ color: "#ffffff" }}
                              /> */}
                          {date
                            ? getCurrentDate(new Date(date))
                            : getCurrentDate()}
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-6 my-auto">
                  <div className="edition-div">
                    {location.pathname != "/" ? null : (
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleClick}
                      >
                        {response1?.map((val) =>
                          val.id === 1 ? (
                            <option value={val?.id} selected>
                              {val?.name}
                            </option>
                          ) : (
                            <option value={val?.id}> {val?.name}</option>
                          )
                        )}
                      </select>
                    )}
                  </div>
                </div>

                <div className=" col-sm-12 col-12 my-auto">
                  {location.pathname != "/" ? (
                    <div className="e-pages-section">
                      <ul className="d-flex header_list_mobile">
                        {location.pathname != "/news-details" ? (
                          <>
                            <li>
                              {/* <Pagination className="">
                                {particulerNews?.innerPages?.map(
                                  (val, index) => (
                                    <>
                                      <Pagination.Item
                                        className="m-0"
                                        key={val.id}
                                        value={val.id}
                                        active={1 + index1 === val.page_no_id}
                                        onClick={() =>
                                          handleSelectChange(val.id)
                                        }
                                      >
                                        {val.page_no_id}
                                      </Pagination.Item>
                                    </>
                                  )
                                )}
                              </Pagination> */}
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={handleSelectChange}
                              >
                                <option disabled>
                                  <img value="" src={pageicon} /> पान क्र.
                                  <span className="page-numb">1</span>
                                </option>
                                {particulerNews?.innerPages?.map((val) =>
                                  1 + index1 === val.page_no_id ? (
                                    <option value={val.id} selected>
                                      {val.page_no_id}
                                    </option>
                                  ) : (
                                    <option value={val.id}>
                                      {val.page_no_id}
                                    </option>
                                  )
                                )}
                              </select>
                            </li>
                            <li style={{ color: "white" }}>
                              / {particulerNews?.innerPages?.length}
                            </li>
                          </>
                        ) : null}

                        {/* <li className="text-li">
                                {particulerNews?.innerPages?.map((val) =>
                                  index1 === particulerNews?.index ? (
                                    <Link
                                      className="link"
                                      to=""
                                      target="_blank"
                                      download
                                    >
                                      <span className="page-dwn-text">
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-file"
                                          className="me-2"
                                        />
                                        पान डाउनलोड करा
                                      </span>
                                    </Link>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </li>  */}
                        {/* 
                       <li className="text-li">
                                {particulerNews?.innerPages?.map((val) =>
                                  index1 === particulerNews?.index ? (
                                    <Link
                                      className="link"
                                      to={IMG_URL + val?.news}
                                      target="_blank"
                                      download
                                    >
                                      <span className="page-dwn-text">
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-file"
                                          className="me-2"
                                        />
                                        पान डाउनलोड करा
                                      </span>
                                    </Link>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </li>  */}

                        {particulerNews?.innerPages?.map((val, index) =>
                          index1 === index ? (
                            <>
                              {location.pathname != "/news-details" ? (
                                <li className="text-li1 ">
                                  <Link
                                    download
                                    className="link"
                                    onClick={() => {
                                      downloadImage(val?.id);
                                    }}
                                  >
                                    <span className="page-dwn-text">
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-download"
                                        className="me-2 mt-2 pageDownload_icon"
                                      />
                                      {/* पान डाउनलोड करा */}
                                    </span>
                                  </Link>
                                </li>
                              ) : null}
                              <li className="text-li2">
                                <Link className="link">
                                  <span
                                    className="page-dwn-text"
                                    onClick={() => {
                                      paperDownload(val?.paper_id);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-file-zipper"
                                      className="me-2 mt-2 pageDownload_icon"
                                    />
                                    {/* पूर्ण आवृत्ती डाउनलोड करा */}
                                  </span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )
                        )}
                      </ul>
                    </div>
                  ) : null}
                </div>

                {/* <div className="col-md-2 my-auto">
                        <div className="edition-div">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={handleClick}
                          >
                            {response?.map((val) =>
                              val.id === 1 ? (
                                <option value={val?.id} selected>
                                  {" "}
                                  {val?.name}
                                </option>
                              ) : (
                                <option value={val?.id}> {val?.name}</option>
                              )
                            )}
                          </select>
                        </div>
                      </div> */}

                <div className="col-md-2 ms-auto my-auto">
                  <div className="social-icons-stripped">
                    <div className="row justify-content-end">
                      <div className="icons text-center">
                        {socialmedia?.map((data, index) => {
                          return (
                            <Link key={index}>
                              <img
                                onClick={() => window.open(data?.url)}
                                className="icon"
                                src={IMG_URL + data?.icons}
                              />
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* end mobile-header */}
        </div>
      </div>
    </>
  );
};
export default Header;
