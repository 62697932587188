import React from "react";
import "../Footer/Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <section className="footer mt-5">
        <div className="container-fluid">
          <p className="feedback text-center ">
            For any queries or feedback email us on info@rashtrasanchar.com
          </p>
          <p className="advertisement text-center">
            For advertisements on this page please email us on:
            info@rashtrasanchar.com Copyright © 2023 A Pandhari Sanchar Media
            Pvt Ltd. Initiative <br />
            Powered by:{" "}
            <Link
              to="https://profcymaglobal.com"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              {" "}
              Profcyma Solutions Pvt. Ltd.{" "}
            </Link>{" "}
            +91 9607755557
          </p>
          <div className="container">
            <div className="border-line"></div>
          </div>
          <p className="advertisement text-center mt-1">
            {/* A Pandhari Sanchaar Media Pvt. Ltd. is a leading multi platform
            media company with interests in a diversified portfolio */}
          </p>
        </div>
      </section>
    </>
  );
};
export default Footer;
