import { useRef } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import Pdffile from "../../Firstmain/pdf-files/sample.pdf";
import parse from "html-react-parser";
import { saveAs } from "file-saver";
import "../SecondPaper/SecondPaper.css";
import Header from "../../header/Header";
import NewsExpandSection from "../../NewsExapndSection/NewsExapndsection";
import galery from "../images/galery.png";
import cycle from "../images/cycle.png";
import text from "../images/text.png";
import arrow from "../images/arrow.png";
import download from "../images/download.png";
import paper1 from "../images/paper1.png";
import pageicon from "../../header/Image/page-icon.png";
import facebook from "../images/facebook.png";
import facebook22 from "../images/facebook22.png";
import whatsapp from "../images/whatsapp.png";
import telegram from "../images/telegram.png";
import instagram from "../images/instagram.png";
import twiter from "../images/twiter.png";
import youtube from "../images/youtube.png";
import zoompaper1 from "../images/zoompaper1.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import location from "../../NewsExapndSection/images/location.png";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import dhillon from "../images/dhillon.png";
import RASHTRA from "../images/RASHTRA.png";
import IMGLOGO from "../../header/Image/rashtrasanchar_logo.png";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, TelegramIcon } from "react-share";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";
import ImageZoom from "react-image-zooom";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
import SwiperCore from "swiper";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useContext } from "react";
import { Context } from "../../../utils/context";
import { Hourglass, ColorRing } from "react-loader-spinner";
// SwiperCore.use([Pagination]);
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function MyVerticallyCenteredModal(props) {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);
  // console.log(headerText);
  // const id = props.id;
  // console.log(id);
  const {
    getData,
    myData,
    shortPaper,
    setShortPaper,
    IMG_URL,
    advertisements,
    particulerNews,
    index1,
  } = useContext(Context);

  const imageUrl = "https://admin.rashtrasanchar.profcymabackend.com/";

  function paperDownload(id) {
    const xhr = new XMLHttpRequest();
    const pdfUrl =
      process.env.REACT_APP_BASE_URL + `/download-pdf?paper_id=${id}`;
    xhr.open("GET", pdfUrl);

    // Fetch the PDF as a Blob
    fetch(pdfUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const blob = new Blob([buffer], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "downloaded-file.pdf");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.error("Error downloading PDF file:", error);
      });
  }

  const [isCopied, setIsCopied] = useState(false);

  // console.log(shortPaper);
  const handleCopyClick = (id) => {
    // console.log(shortPaper);
    navigator.clipboard
      .writeText(
        `https://rashtrasanchar.profcymadev.in/article-view/${props.id}`
      )
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Reset the message after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  const [isloading, setLoading] = useState(true);

  const handleClicknews = async (e) => {
    if (props.id) {
      // setLoading(true);
      // console.log(e.target.id);
      // navigate(`/news-details/${e.target.id}`);
      const res = await getData(`/getSection?unique_no=${props.id}`);
      // console.log(res);
      if (res?.success) {
        await setShortPaper(res.data);
        // setLoading(false);
        // navigate("/news-details");
      }
    }
  };

  const [socialmedia, setSocialmedia] = useState();
  const getSocialMedia = async () => {
    const res = await getData("/getSocial");
    // console.log(res.data.socialMedia);
    if (res?.success) {
      await setSocialmedia(res.data.socialMedia);
    }
  };
  // console.log(index1);
  // Function to block future dates

  useEffect(() => {
    getSocialMedia();
  }, []);

  useEffect(() => {
    // if (props.id) {
    handleClicknews();
    // }
  }, [props.id]);
  // isloading ? (
  //   <>
  //     <ColorRing
  //       className="blocks-wrapper"
  //       visible={true}
  //       height="80"
  //       width="80"
  //       ariaLabel="blocks-loading"
  //       wrapperStyle={{}}
  //       wrapperClass="blocks-wrapper"
  //       colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
  //     />
  //   </>
  // ) :
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="modal-header" />
      {/* <i class="fa fa-times" aria-hidden="true"></i> */}
      {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      {/* </Modal.Header> */}
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
      >
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-xl-12">
                <div className="">
                  <div className="news-details-page-view" style={{}}>
                    <img className="Paper-News-top-logo" src={IMGLOGO} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              {shortPaper?.quilt ? (
                <>
                  {/* <div className="final-paper-height">
                    <div
                      className="news-details-page-view"
                      // style={{
                      //   overflow: "hidden",
                      //   border: "1px solid #000",
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      //   width: "auto",
                      //   height: "auto",
                      // }}
                    > */}
                  <img
                    alt="No Page"
                    className=""
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    src={IMG_URL + shortPaper?.image}
                  />
                  {/* <ImageZoom
                        alt="No Page"
                        className=""
                        style={{
                          width:
                            "100%" ,
                          height:
                            "auto" ,
                        }}
                        zoom="200"
                        src={IMG_URL + shortPaper?.image}
                      /> */}
                  {/* </div>
                  </div> */}
                </>
              ) : (
                <>
                  <div className="final-paper-height">
                    <div
                      className="news-details-page-view"
                      style={{
                        overflow: "hidden",
                        border: "1px solid #000",
                        width: shortPaper?.firstCord?.width
                          ? `${shortPaper?.firstCord?.width}px`
                          : undefined,
                        height: shortPaper?.firstCord?.height
                          ? `${shortPaper?.firstCord?.height}px`
                          : undefined,
                      }}
                    >
                      <img
                        alt="No Page"
                        className=""
                        src={IMG_URL + shortPaper?.paper?.news}
                        style={{
                          marginTop: shortPaper?.firstCord?.y_co
                            ? `${-shortPaper?.firstCord?.y_co}px`
                            : undefined,
                          marginLeft: shortPaper?.firstCord?.x_co
                            ? `${-shortPaper?.firstCord?.x_co}px`
                            : undefined,
                        }}
                        zoom="200"
                      />
                      {/* <ImageZoom
                        alt="No Page"
                        className=""
                        src={IMG_URL + shortPaper?.paper?.news}
                        style={{
                          marginTop: shortPaper?.firstCord?.y_co
                            ? `${-shortPaper?.firstCord?.y_co}px`
                            : undefined,
                          marginLeft: shortPaper?.firstCord?.x_co
                            ? `${-shortPaper?.firstCord?.x_co}px`
                            : undefined,
                        }}
                        zoom="300"
                      /> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <div className="col-md-2 ms-auto my-auto"> */}
        {/* <div className="social-icons-stripped"> */}
        {/* <div className="row "> */}
        {/* <div className="icons text-center"> */}
        {/* {socialmedia?.map((data, index) => {
          return ( */}
        {/* <Link key={index}>
              <img
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone&text=https://fizo.profcymadev.in/&type&app_absent=0"
                  )
                }
                className="icon icons-social"
                src={IMG_URL + data?.icons}
              />
            </Link> */}
        <Link
          target="_blank"
          to={`https://api.whatsapp.com/send/?phone&text=https://epaper.rashtrasanchar.com${headerText}&type&app_absent=0`}
        >
          <img className="icon icons-social" src={whatsapp} />
        </Link>

        <TelegramShareButton
          // image={"https://www.w3schools.com/images/lamp.jpg"}
          url={`https://epaper.rashtrasanchar.com` + headerText}
          // className="Demo__some-network__share-button"
        >
          <img className="icon icons-social" src={telegram} />
        </TelegramShareButton>

        <FacebookShareButton
          // image={"https://www.w3schools.com/images/lamp.jpg"}
          url={`https://epaper.rashtrasanchar.com` + headerText}
          // className="Demo__some-network__share-button"
        >
          <img className="icon icons-social" src={facebook22} />
        </FacebookShareButton>
        {/* );
        })} */}
        {/* </div>
        </div> */}
        {/* </div> */}
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  );
}
function SecondPaper() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const swiperRef = useRef(null);
  const [isloading, setLoading] = useState(true);
  const navigate = useNavigate();
  const imageUrl = "https://admin.rashtrasanchar.profcymabackend.com/";
  const {
    getData,
    mainPage,
    setMainPage,
    zoneid,
    setZoneid,
    date,
    setDate,
    setmyData,
    shortPaper,
    setShortPaper,
    particulerNews,
    setParticulerNews,
    papercurrentIndex,
    setPaperCurrentIndex,
    IMG_URL,
    index1,
    setIndex,
    advertisements,
    setadvertisement,
  } = useContext(Context);
  const { id } = useParams();

  const [floatings, setFloatNews] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [codId, setCodId] = useState();
  const handleClicknews = async (e) => {
    // console.log(e.target.id);
    setCodId(e.target.id);
    // navigate(`/news-details/${e.target.id}`);
    setModalShow(true);

    // const sectionPaper = await getData(`/getSection?unique_no=${e.target.id}`);
    // if (sectionPaper?.success) {
    //   await setShortPaper(sectionPaper.data);
    //   setLoading(false);
    //   navigate("/news-details");
    // }
  };

  const getperticularNewsGet = async () => {
    setLoading(true);
    const resperticularNewsGet = await getData(`/perticularNews?id=${id}`);
    if (resperticularNewsGet?.success) {
      console.log(resperticularNewsGet);
      await setParticulerNews(resperticularNewsGet.data);
      // await setIndex(resperticularNewsGet.data.index);
      setLoading(false);
    }
  };

  // console.log(particulerNews?.index);

  const handleSelectChange = async (val = 0) => {
    // console.log(particulerNews);
    await setLoading(true);
    const response = await getData(`/perticularNews?page_id=${val}`);
    await setIndex(response?.data?.index);
    await setParticulerNews(response?.data);
    await setLoading(false);
  };

  const [resWidth, setresWidth] = useState([]);

  const handleResize = async () => {
    await setScreenWidth(window.innerWidth);
    await setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    setSwiperData();
  }, [particulerNews]);

  const setSwiperData = async () => {
    const resWidth = [];
    if (screenWidth < 600) {
      const fixwidth = 600;
      const fixvar = ((fixwidth - screenWidth) / 10) * 1.75;
      // console.log(fixvar);
      await particulerNews?.innerPages?.forEach(async (val) => {
        const newObj = {
          id: val.id,
          news: val.news,
          // width: val.width - (val.width / 100) * fixvar,
          // height: val.height - (val.height / 100) * fixvar,
          width: val.width,
          height: val.height,
          coord: [], // Initialize an empty array property for coords
        };

        await val?.coord?.forEach((value) => {
          const coordObj = {
            unique_no: value.unique_no,
            width: value.width - (value.width / 100) * fixvar,
            // - (value.width / 100) * fixvar,
            height: value.height - (value.height / 100) * fixvar,
            //  - (value.height / 100) * fixvar,
            x_co: value.x_co - (value.x_co / 100) * fixvar,
            // - (value.x_co / 100) * fixvar,
            y_co: value.y_co - (value.y_co / 100) * fixvar,
            // - (value.y_co / 100) * fixvar,
          };
          newObj.coord.push(coordObj); // Push coordObj into the 'coords' array
        });

        await resWidth.push(newObj);
      });
      await setresWidth(resWidth);
    }

    if (screenWidth >= 600) {
      const fixwidth = 600;
      const fixvar = ((fixwidth - screenWidth) / 10) * 0.2;
      // console.log(fixvar);
      await particulerNews?.innerPages?.forEach(async (val) => {
        const newObj = {
          id: val.id,
          news: val.news,
          width: val.width,
          height: val.height,
          coord: [], // Initialize an empty array property for coords
        };

        await val?.coord?.forEach((value) => {
          const coordObj = {
            unique_no: value.unique_no,
            width: value.width,
            // - (value.width / 100) * fixvar,
            height: value.height,
            //  - (value.height / 100) * fixvar,
            x_co: value.x_co,
            // - (value.x_co / 100) * fixvar,
            y_co: value.y_co,
            // - (value.y_co / 100) * fixvar,
          };
          newObj.coord.push(coordObj); // Push coordObj into the 'coords' array
        });

        resWidth.push(newObj);
      });
      await setresWidth(resWidth);
    }
  };

  const getFloatNews = async () => {
    setLoading(true);
    const floats = await getData("/floatNews");
    if (floats?.success) {
      setFloatNews(floats?.data?.floatingNews);
      setLoading(false);
    }
  };
  const getadvertisement = async () => {
    setLoading(true);
    const adverties = await getData("/advertisement");
    if (adverties?.success) {
      setadvertisement(adverties.data);
      setLoading(false);
    }
  };

  const handleClicks = async (e) => {
    await setmyData(e.target);
  };
  // console.log("asdasd",index1);
  const [numPages, setNumPages] = useState(3); // Initial number of pages
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      // console.log(index);
      return '<span class="' + className + '">' + index + "</span>";
    },
  };

  useEffect(() => {
    // function handleResize() {
    //   setScreenWidth(window.innerWidth);
    //   setScreenHeight(window.innerHeight);
    // }
    if (swiperRef.current) {
      swiperRef.current.swiper.update();
    }
    window.addEventListener("resize", handleResize);
    getperticularNewsGet();
    getFloatNews();
    getadvertisement();
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    // console.log("handleMouseEnter");
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  return isloading ? (
    <>
      <ColorRing
        className="blocks-wrapper"
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
      />
    </>
  ) : (
    <>
      <div className="row me-0">
        {/* start left sidebar papper scroller section */}
        <div className="col-xxl-2 col-xl-3 col-md-3 bg-colll">
          {/* <NewsExpandSection /> */}
          <section className="paper-scroll">
            {/* <div className="PageNoSection_mobileView row mt-2  ">
                <div className="d-flex">
                  <div className="col-lg-6 col-sm-6 col-6">
                  {location.pathname != "/" ? (
                  <div className="e-pages-section">
                      <ul className="d-flex pageNO">
                        <li>
                            <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={handleSelectChange}
                            >
                            <option selected>
                                <img value src={pageicon} /> पान क्र.
                                <span className="page-numb">1</span>
                            </option>
                            {particulerNews?.innerPages?.map((val) => (
                                <option value={val.id}>{val.page_no_id}</option>
                            ))}
                            </select>
                        </li>
                      </ul>
                  </div>
                  ) : null}
                  </div> 
                  <div className="col-lg-6 col-sm-6 col-6">
                    <ul className="Download_paper">
                      <li className="text-li">
                          <Link className="link">
                          <span className="page-dwn-text" onClick={paperDownload}>
                              <FontAwesomeIcon
                              icon="fa-solid fa-file-circle-plus"
                              className="me-2"
                              />
                              पूर्ण आवृत्ती डाउनलोड करा
                          </span>
                          </Link>
                      </li>

                    </ul>
                  </div>

                </div>
              </div> */}

            {/* <div className="PageNoSection_mobileView row mt-2">
                  <div className="col-lg-4 col-sm-3 col-3">
                    <ul className="pageNO">
                      <li>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option disabled>
                                          <img value="" src={pageicon} /> पान क्र.
                                          <span className="page-numb">1</span>
                                        </option>
                                        {particulerNews?.innerPages?.map((val) => (
                                          <option value={val.id}>
                                            {val.page_no_id}
                                          </option>
                                        ))}
                                      </select>

                                      
                      </li>

                    </ul>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-4">
                    <ul className="pageNO">
                      <li className="text-li">
                                            <Link
                                              download
                                              className="link"
                                              onClick={() => {
                                                // downloadImage(val?.id);
                                              }}
                                              // href={IMG_URL + val?.news}
                                              // onClick={() => {
                                              //   downloadImage(IMG_URL + val?.news);
                                              // }}
                                              // target="_top"
                                              // download="jpg"
                                              // rel="noreferrer"
                                            >
                                              <span className="page-dwn-text">
                                                <FontAwesomeIcon
                                                  icon="fa-solid fa-file"
                                                  className="me-2"
                                                />
                                                पान डाउनलोड करा
                                              </span>
                                            </Link>
                      </li>

                    </ul>
                  </div>
                  <div className="col-lg-4 col-sm-5 col-5">
                    <ul className="pageNO">
                      <li className="text-li">
                                          <Link className="link">
                                            <span
                                              className="page-dwn-text"
                                              onClick={() => {
                                                // paperDownload(val?.paper_id);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon="fa-solid fa-file-circle-plus"
                                                className="me-2"
                                              />
                                              पूर्ण आवृत्ती डाउनलोड करा
                                            </span>
                                          </Link>
                      </li>

                    </ul>
                  </div>
                </div> */}

            <div className="row">
              <div className="col-md-12">
                <div className="tabs-sec-div">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-hellos-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-hellos"
                        type="button"
                        role="tab"
                        aria-controls="pills-hellos"
                        aria-selected="true"
                      >
                        Hellos
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-supplements-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-supplements"
                        type="button"
                        role="tab"
                        aria-controls="pills-supplements"
                        aria-selected="false"
                      >
                        Supplements
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-hellos"
                      role="tabpanel"
                      aria-labelledby="pills-hellos-tab"
                      tabindex="0"
                    >
                      <div className="small-paper-view">
                        {particulerNews?.innerPages?.map((val) => (
                          <div onClick={() => handleSelectChange(val?.id)}>
                            <img
                              className="paper-img mt-4"
                              src={IMG_URL + val?.news}
                              alt="..."
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="pills-supplements"
                      role="tabpanel"
                      aria-labelledby="pills-supplements-tab"
                      tabindex="0"
                    >
                      <div className="small-paper-view">
                        {particulerNews?.innerPages?.map((val) => (
                          <Link onClick={() => handleSelectChange(val?.id)}>
                            <img
                              className="paper-img mt-4"
                              src={IMG_URL + val?.news}
                              alt="..."
                            />
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* end left sidebar papper scroller section */}
        <>
          {/* <MyVerticallyCenteredModal
            show={modalShow}
            id={codId}
            onHide={() => {setModalShow(false) setCodId(undefined)}}
          /> */}
          <MyVerticallyCenteredModal
            show={modalShow}
            id={codId}
            onHide={() => {
              setModalShow(false);
              setCodId(undefined);
            }}
          />
        </>
        {/* start swiper newspaper scroller */}
        <div className="col-xxl-10 col-xl-9 col-md-9">
          <div className="main-content-section">
            <div className="related-new-slider-div">
              <Swiper
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                slidesPerView={4}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                className="mySwiper"
              >
                {floatings?.map((val, index) => (
                  <SwiperSlide key={index}>
                    <div className="slide-news-div">
                      <div className="d-flex">
                        <div className="img-div">
                          <img
                            className="news-imggg"
                            src={imageUrl + val?.image}
                          />
                        </div>
                        <Link
                          to={val?.url}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className="news-div my-auto">
                            <p>
                              {val?.heading.length > 60
                                ? val.heading.substring(0, 60) + "..."
                                : val.heading}
                            </p>
                            {/* <p>{val?.heading}</p> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12">
                <div className="row  justify-content-center">
                  <div className="col-xxl-9 col-xl-11 col-lg-12 col-md-11 mx-auto">
                    <div className={`main-swiper`}>
                      {index1 == 0 ? (
                        <></>
                      ) : (
                        <button
                          className="prev-btn"
                          onClick={() => {
                            handleSelectChange(
                              particulerNews?.innerPages[index1 - 1]?.id
                            );
                          }}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                        </button>
                      )}
                      {particulerNews?.innerPages?.length === index1 + 1 ? (
                        <></>
                      ) : (
                        <button
                          className="next-btn"
                          onClick={() => {
                            handleSelectChange(
                              particulerNews?.innerPages[index1 + 1]?.id
                            );
                            // handleSelectChange(index1 + 1);
                          }}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </button>
                      )}

                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        modules={[Pagination]}
                        className={`mySwiper`}
                        ref={swiperRef}
                        slideToClickedSlide={true}
                        onActiveIndexChange={index1}
                        // onSlideChange={handleSlideChange}
                      >
                        {resWidth?.map((val, index) =>
                          index1 === index ? (
                            <SwiperSlide key={index}>
                              <div className="row">
                                <div className="col-12">
                                  <div className="news-ppr-divvv">
                                    <div
                                      class="dynamicDiv"
                                      style={{
                                        position: "relative",
                                        Width: "100%",
                                        // width: val?.width
                                        //   ? `${val?.width}px`
                                        //   : undefined,
                                      }}
                                      onClick={handleClicks}
                                    >
                                      {val?.coord?.map((corda) => (
                                        <div className="boxxxxxx-div">
                                          <a
                                            class="boxxxxxx"
                                            onClick={handleClicknews}
                                            id={corda?.unique_no}
                                            style={{
                                              width: corda?.width
                                                ? `${corda?.width}px `
                                                : undefined,
                                              height: corda?.height
                                                ? `${corda?.height}px`
                                                : undefined,
                                              marginLeft: corda?.x_co
                                                ? `${corda?.x_co}px`
                                                : undefined,
                                              marginTop: corda?.y_co
                                                ? `${corda?.y_co}px`
                                                : undefined,
                                            }}
                                          ></a>
                                        </div>
                                      ))}
                                      {/* <>{index + " " + index1}</> */}
                                      <div className="art-view-img-div">
                                        <img
                                          className="rashtrakiran me-2"
                                          src={imageUrl + val?.news}
                                          style={{
                                            position: "relative",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          ) : null
                        )}
                      </Swiper>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-11 mx-auto">
                    <div>
                      {/* start video add section  */}
                      {advertisements?.advertisement?.map((word, index) =>
                        word.type === "midad" ? (
                          <div className="add-video-sec">
                            <div className="row">
                              <div className="col-md-12">
                                <div className=" video-div">
                                  <video
                                    className="articleview-add-video"
                                    playsInline
                                    loop
                                    muted
                                    controls
                                    alt="All the devices"
                                    src={imageUrl + word.file}
                                    ref={videoEl}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )}
                      {/* end video add section  */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 mx-auto">
                <div className="row justify-content-center">
                  <div
                    className="jahirat mt-2"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <p className="name text-center mt-2">जाहिरात</p>

                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      ref={swiperRefLocal}
                      loop={true}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      {advertisements?.advertisement?.map((word, index) =>
                        word.type === "sidead" ? (
                          <SwiperSlide key={index}>
                            <span>
                              <img
                                className="rashtra me-2"
                                src={imageUrl + word?.file}
                              />
                            </span>
                          </SwiperSlide>
                        ) : null
                      )}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end swiper newspaper scroller */}
      </div>
    </>
  );
}
export default SecondPaper;
